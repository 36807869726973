.contact-image {
    background-image: url(contact.png);
}
.page-title.contact-image {
    background-attachment: inherit;
}
.page-title.contact-image:before {
    background: none;
    opacity: 1;
    background-image: linear-gradient(to right, rgb(7 81 84),rgba(255,0,0,0));
}
.contact-ripple canvas {
    z-index: 1 !important;
}
#map iframe {
    width: 100%;
    height: 432px;
    margin-bottom: -10px;
}
