.service-image {
    background-image: url(service.png);
}
.page-title.service-image {
    background-attachment: inherit;
}
.page-title.service-image:before {
    background: none;
    opacity: 1;
    background-image: linear-gradient(to right, rgb(7 81 84),rgba(255,0,0,0));
}
.service-ripple canvas {
    z-index: 1 !important;
}
.services-img.services-img-detail img {
    border-radius: 0 !important;
    box-shadow: none;
}
.pl-20 {
    padding-left: 20px;
}
